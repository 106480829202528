import {createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
    name: "languages",
    initialState: {
        languages: {}
    },
    reducers: {
        setLangs(state, payload) {
            state.languages = payload.payload;
        }
    },
});


export default languageSlice.reducer;