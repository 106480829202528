// ** Reducers Imports
import appStates from "../entity/appState";
import authStates from "../entity/authState";
import navigationStates from "../entity/navigationStates";
import languageState from "../entity/languageState"

const rootReducer = {
  auth: authStates,
  apps: appStates,
  nav: navigationStates,
  languages: languageState
};

export default rootReducer;
