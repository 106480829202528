import React from "react";
import logo from "@/assets/media/brs_logo.png";
import { Typography, Spin } from "antd-v5";
import { LoadingOutlined } from "@ant-design/icons";
import Config from "@/config/env";
const { colorPrimary, colorSecondary3, colorSec } = Config;

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 50, color: "white", fontWeight: "bold" }}
    spin
  />
);

export function LoadingGreen({ style, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...style,
        background: `linear-gradient(45deg, ${colorPrimary}, ${colorSec})`,
      }}
      {...props}
    >
      <Spin tip="Loading" size="large" indicator={antIcon} />
      <Typography style={{ fontSize: 50, color: "white" }}>
        Memuat ...
      </Typography>
    </div>
  );
}

export function LoadingGreenSpin({ style, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      {...props}
    >
      {/* <img width={"4%"} src={logo} /> */}
      {/* <img width={"8%"} style={{marginTop: "-2rem"}}src='/assets/animation/loading.svg'></img>
       */}
      <Typography>Loading</Typography>
    </div>
  );
}
