import "antd/dist/antd.less";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { store } from "./redux/store";
import reportWebVitals from "./libs/reportWebVitals";
import "./assets/styles/app.css";
import { LoadingGreen } from "./libs/components/LoadingGreen";
const App = lazy(() => import("./web-content/boundary/App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <React.Suspense fallback={<LoadingGreen style={{height: "100vh"}}/>}>
            <React.Fragment>
                <App />
                <ToastContainer newestOnTop autoClose={3000} />
            </React.Fragment>
        </React.Suspense>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
